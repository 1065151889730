import React from "react"
import { graphql } from 'gatsby'
import { TypographyStyle, GoogleFont } from 'react-typography'
import Layout from "../components/layout"
import Card from "../components/card"
/* import Image from "../components/image" */
import SEO from "../components/seo"
import typography from '../utils/typography'

const IndexPage = ({data}) => {
  const posts = data.allContentfulBlogPost.edges

  return (
    <>
    <head>
      <TypographyStyle typography={typography} />
      <GoogleFont typography={typography} />
    </head>
    <Layout>
      <SEO title="Home" />
      <div className="container -ml-4 pl-4 lg:mt-20 content-start flex flex-wrap justify-start">
        {posts.map(({node: post, index}) => (
          <Card key={post.id} {...post} />
        ))}
      </div>
    </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allContentfulBlogPost (filter: {node_locale: {eq: "en-US"}}){
      edges {
        node {
          id
          title
          slug
          author {
            name
          }
          body {
            body
          }
          tags
          category
          createdAt
          description {
            description
          }
          publishDate(formatString:"DD MMMM, YYYY")
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
export default IndexPage
